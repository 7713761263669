<template>
  <nav class="mt-6 relative z-20">
    <div class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
          <li>
            <MainMenuLink :menu="menu" :allow-active="true" />

            <!-- BEGIN: Second Child -->
            <ul v-if="menu.subMenu">
              <li
                v-for="(subMenu, subMenuKey) in menu.subMenu"
                :key="subMenuKey"
              >
                <MainMenuLink :menu="subMenu" />

                <!-- BEGIN: Third Child -->
                <ul v-if="subMenu.subMenu">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <MainMenuLink :menu="lastSubMenu" />
                  </li>
                </ul>
                <!-- END: Third Child -->
              </li>
            </ul>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { nestedMenu } from "@/components/composables/menu";
import MainMenuLink from "./MainMenuLink.vue";

export default defineComponent({
  components: {
    MainMenuLink,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const path = computed(() => route.path);
    const mainMenu = computed(() =>
      nestedMenu(store.state.mainMenu.menu, route)
    );

    watch(path, () => {
      formattedMenu.value = JSON.parse(JSON.stringify(mainMenu.value));
    });

    onMounted(() => {
      formattedMenu.value = JSON.parse(JSON.stringify(mainMenu.value));
    });

    return {
      formattedMenu,
      router,
    };
  },
});
</script>
