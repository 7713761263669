
import { defineComponent } from "vue";
import Alert from "@/components/ui/alert/Alert.vue";
import MobileMenu from "@/components/layout/mobile-menu/MobileMenu.vue";
import Wrap from "@/components/layout/wrap/Wrap.vue";
import Header from "@/components/layout/header/Header.vue";
import Footer from "@/components/layout/footer/Footer.vue";
import Content from "@/components/layout/content/Content.vue";

export default defineComponent({
  components: {
    Alert,
    MobileMenu,
    Wrap,
    Header,
    Footer,
    Content,
  },
  props: [],
  setup() {
    return {};
  },
});
