<template>
  <div class="pt-3 md:pt-6 pb-12 px-6">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  components: {},
  setup() {
    return {};
  },
});
</script>
