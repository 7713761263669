
import { defineComponent } from "vue";
import { ChevronDownIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    ChevronDownIcon,
  },
  props: ["menu", "allowActive"],
  setup() {
    return {};
  },
});
