
import { defineComponent } from "vue";
import MainMenu from "@/components/layout/main-menu/MainMenu.vue";

export default defineComponent({
  components: {
    MainMenu,
  },
  props: [],
  setup() {
    return {};
  },
});
