
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  components: {},
  setup() {
    return {};
  },
});
