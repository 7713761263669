
import { defineComponent } from "vue";
import { buttonVariants } from "./button-variants";

export default defineComponent({
  props: {
    size: {
      type: String,
      required: false,
      default: "default",
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup() {
    return {
      buttonVariants,
    };
  },
});
