<template>
  <div>
    <div class="justify-center md:flex">
      <slot name="tabs"></slot>
    </div>
    <div class="py-6">
      <slot name="tab-content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from "vue";

export default defineComponent({
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  components: {},
  setup(props) {
    // don't use toRef here:
    // we only want to use the active tab prop to set the initial value
    // rather than tracking it as a reactive prop
    const activeTab = ref(props.activeTab);

    const updateActiveTab = (slug: string) => {
      activeTab.value = slug;
    };

    provide("activeTab", activeTab);
    provide("updateActiveTab", updateActiveTab);

    return {};
  },
});
</script>
