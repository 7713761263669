
import { defineComponent } from "vue";
import HeaderLogo from "./HeaderLogo.vue";
import HeaderBreadcrumb from "./HeaderBreadcrumb.vue";
// import HeaderNotifications from "./HeaderNotifications.vue";
// import HeaderAccount from "./HeaderAccount.vue";

export default defineComponent({
  components: {
    HeaderLogo,
    HeaderBreadcrumb,
    // HeaderNotifications,
    // HeaderAccount,
  },
  setup() {
    return {};
  },
});
