<template>
  <router-link
    class="button button-link group rounded-full border-2 transition duration-300 inline-flex items-center hover:shadow-lg"
    :class="buttonVariants({ type, size, disabled })"
  >
    <slot></slot>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { buttonVariants } from "./button-variants";

export default defineComponent({
  props: {
    size: {
      type: String,
      required: false,
      default: "default",
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup() {
    return {
      buttonVariants,
    };
  },
});
</script>
