<template>
  <div class="container">
    <!-- BEGIN: Error Page -->
    <div
      class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
    >
      <div class="-intro-x lg:mr-20">BizBrain</div>
      <div class="text-white mt-10 lg:mt-0">
        <div class="intro-x text-8xl font-medium">404</div>
        <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
          Oops. This page has gone missing.
        </div>
        <div class="intro-x text-lg mt-3">
          You may have mistyped the address or the page may have moved.
        </div>
        <router-link
          :to="{ name: 'home' }"
          class="intro-x button button--lg inline-block border border-white dark:border-dark-5 dark:text-gray-300 mt-10"
        >
          Back to Home
        </router-link>
      </div>
    </div>
    <!-- END: Error Page -->
  </div>
</template>
