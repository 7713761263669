<template>
  <div
    class="editorial"
    :class="{
      'centre md:max-w-3/4 mx-auto': align === 'centre',
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    align: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {},
  setup() {
    return {};
  },
});
</script>
