import { Store as VuexStore, Module } from "vuex";
import { State as LocalState, state } from "./state";
import { State as RootState } from "@/store";
import { Getters, getters } from "./getters";
import { Namespaced } from "../namespaced";

// State type
export type State = LocalState;

type NamespacedGetters = Namespaced<Getters, "mainMenu">;

// Setup store type
export type Store<S = LocalState> = Omit<VuexStore<S>, "getters"> & {
  getters: {
    [K in keyof NamespacedGetters]: ReturnType<NamespacedGetters[K]>;
  };
};

export const mainMenu: Module<LocalState, RootState> = {
  namespaced: true,
  state,
  getters,
};
