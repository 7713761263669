import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import RootLayout from "@/components/layout/root/Root.vue";
import HomeView from "@/components/views/home/Home.vue";
import ErrorView from "@/components/views/error/Error.vue";
import ComponentLibraryView from "@/components/views/component-library/ComponentLibrary.vue";

declare module "vue-router" {
  interface RouteMeta {
    breadcrumb: any;
    model?: string;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: RootLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        meta: {
          breadcrumb: [{ title: "Home" }],
        },
      },
      {
        path: "component-library/",
        name: "component-library",
        component: ComponentLibraryView,
        meta: {
          breadcrumb: [{ title: "Component Library" }],
        },
      },
    ],
  },
  {
    path: "/error/",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

export default router;
