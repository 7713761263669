<template>
  <div
    class="relative z-50 col-span-12"
    :class="{
      'mt-3': extraOverlap === true,
      'mt-6': extraOverlap === false,
    }"
  >
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    extraOverlap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup() {
    return {};
  },
});
</script>
