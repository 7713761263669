<template>
  <div class="relative overflow-hidden">
    <div class="py-12 px-6 md:w-1/2 md:mx-6 text-cebai-primary relative z-20">
      <Editorial>
        <h1>BizBrain</h1>
      </Editorial>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";

export default defineComponent({
  components: {
    Editorial,
  },
  setup() {
    return {};
  },
});
</script>
