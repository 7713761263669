<template>
  <footer
    class="text-white text-base border-t-2 border-cebai-secondary mt-12 pt-12"
  >
    <div class="max-w-screen-xl mx-auto">
      <div class="md:flex items-start justify-start">
        <ul class="md:px-12 mb-8">
          <li
            class="py-1"
            v-for="primaryLink in primaryLinks"
            :key="primaryLink.slug"
          >
            <router-link
              :to="{
                name: primaryLink.slug,
              }"
              class="text-gray-200 hover:text-gray-500"
              >{{ primaryLink.title }}</router-link
            >
          </li>
        </ul>
        <ul class="md:px-12 mb-8">
          <li
            class="py-1"
            v-for="secondaryLink in secondaryLinks"
            :key="secondaryLink.slug"
          >
            <router-link
              :to="{
                name: secondaryLink.slug,
              }"
              class="text-gray-200 hover:text-gray-500"
              >{{ secondaryLink.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="md:flex items-start justify-between md:px-12 text-sm">
        <ul class="flex mb-8">
          <li class="mr-12">
            <a class="text-gray-200 hover:text-gray-500" href="#">
              Terms and Conditions
            </a>
          </li>
          <li>
            <a class="text-gray-200 hover:text-gray-500" href="#">Cookies</a>
          </li>
        </ul>
        <p class="text-gray-500">&copy; {{ year }} CebAI</p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const primaryLinks = [
      {
        title: "Footer - primary link",
        slug: "home",
      },
    ];

    const secondaryLinks = [
      {
        title: "Footer - secondary link",
        slug: "home",
      },
    ];

    const year = new Date().getFullYear();

    return {
      primaryLinks,
      secondaryLinks,
      year,
    };
  },
});
</script>
