type ButtonVariantProps = {
  type: string;
  size: string;
  disabled: boolean;
};

type Variants = {
  [classes: string]: boolean;
};

// calculate
export const buttonVariants = ({
  type = "default",
  size = "default",
  disabled = false,
}: ButtonVariantProps): Variants => {
  return {
    // type variants
    "bg-gray-200 border-gray-200 hover:bg-gray-400 hover:bg-gray-200 text-gray-800":
      type === "default",
    "bg-cebai-primary border-cebai-primary hover:bg-white hover:border-cebai-primary text-white hover:text-cebai-primary":
      type === "primary",
    "bg-cebai-secondary border-cebai-secondary hover:bg-cebai-secondary-tint hover:border-cebai-secondary-tint text-cebai-primary":
      type === "secondary",
    "bg-white border-cebai-primary hover:bg-cebai-primary text-cebai-primary hover:text-white":
      type === "light",
    "border-cebai-primary hover:border-cebai-primary hover:bg-cebai-primary text-cebai-primary hover:text-white":
      type === "outline-dark",
    "border-gray-400 hover:border-gray-400 border hover:bg-white text-cebai-primary":
      type === "outline-gray",
    "border-white hover:border-gray-400 hover:bg-gray-400 text-white hover:text-cebai-primary":
      type === "outline-light",
    "bg-red-600 border-red-600 hover:bg-red-400 hover:border-red-400 text-white":
      type === "danger",
    "bg-transparent border-transparent hover:bg-gray-400 hover:border-gray-400 hover:shadow-none hover:text-cebai-primary":
      type === "text",

    // size variants
    "px-4 py-2 text-base": size === "default",
    "px-3 py-1 text-sm font-normal": size === "small",
    "px-3 py-1 text-xs font-normal": size === "xs",

    // misc variants
    "text-gray-600 opacity-30 pointer-events-none": disabled,
  };
};
