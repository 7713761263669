
import { defineComponent, inject } from "vue";

export default defineComponent({
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
    first: {
      type: Boolean,
      required: false,
      default: false,
    },
    last: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup() {
    const activeTab = inject("activeTab");
    const updateActiveTab = inject("updateActiveTab");

    return {
      activeTab,
      updateActiveTab,
    };
  },
});
