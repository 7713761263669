<template>
  <div class="max-w-screen-xl mx-auto">
    <MainMenu />
    <div class="relative">
      <div class="content p-0 overflow-hidden relative z-10" id="parent">
        <div class="intro-y relative text-base">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainMenu from "@/components/layout/main-menu/MainMenu.vue";

export default defineComponent({
  components: {
    MainMenu,
  },
  props: [],
  setup() {
    return {};
  },
});
</script>
