
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { nestedMenu } from "@/components/composables/menu";
import MainMenuLink from "./MainMenuLink.vue";

export default defineComponent({
  components: {
    MainMenuLink,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const path = computed(() => route.path);
    const mainMenu = computed(() =>
      nestedMenu(store.state.mainMenu.menu, route)
    );

    watch(path, () => {
      formattedMenu.value = JSON.parse(JSON.stringify(mainMenu.value));
    });

    onMounted(() => {
      formattedMenu.value = JSON.parse(JSON.stringify(mainMenu.value));
    });

    return {
      formattedMenu,
      router,
    };
  },
});
