// see:
// https://dev.to/shubhadip/vue-3-vuex-4-modules-typescript-2i2o

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import {
  mainMenu,
  Store as MainMenuStore,
  State as MainMenuState,
} from "./main-menu";
import {
  messages,
  Store as MessagesStore,
  State as MessagesState,
} from "./messages";

export type State = {
  mainMenu: MainMenuState;
  messages: MessagesState;
};

export type Store = MainMenuStore<Pick<State, "mainMenu">> &
  MessagesStore<Pick<State, "messages">>;

// persisted data (kept in localStorage)
const persistedState = createPersistedState({
  paths: [],
});

export const store = createStore({
  modules: {
    mainMenu,
    messages,
  },
  plugins: [persistedState],
});

export function useStore(): Store {
  return store as Store;
}

export default store;
