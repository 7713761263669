import { RouteLocationNormalizedLoaded as Route } from "vue-router";

// Setup side menu
type Menu = {
  icon: string;
  pageName: string;
  title: string;
  active?: boolean;
  activeClass?: string;
  activeDropdown?: boolean;
  ignore?: boolean;
  subMenu?: Menu[];
  params?: any;
};

const findActiveMenu = (subMenu: Array<Menu>, route: Route): boolean => {
  let match = false;
  subMenu.forEach((item) => {
    if (item.pageName === route.name && !item.ignore) {
      match = true;
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route);
    }
  });
  return match;
};

const nestedMenu = (
  menu: Array<Menu | string>,
  route: Route
): Array<Menu | string> => {
  menu.forEach((item, key) => {
    if (typeof item !== "string") {
      let menuItem = menu[key] as typeof item;
      menuItem.active =
        (item.pageName === route.name ||
          (item.subMenu && findActiveMenu(item.subMenu, route))) &&
        !item.ignore;
      menuItem.activeClass = (menuItem.active && item.activeClass) || "";

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route);
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route),
        };
      }
    }
  });

  return menu;
};

export { Menu, nestedMenu };
