<template>
  <div class="-intro-x breadcrumb breadcrumb--light mr-auto">
    <template v-for="(crumb, index) in breadcrumb" :key="index">
      <template v-if="crumb.name">
        <router-link :to="{ name: crumb.name }" tag="a">
          {{ crumb.title }}
        </router-link>
        <ChevronRightIcon class="breadcrumb__icon" />
      </template>
      <span v-else class="breadcrumb--active">{{ crumb.title }}</span>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { ChevronRightIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    ChevronRightIcon,
  },
  setup() {
    const route = useRoute();
    const breadcrumb = computed(() => route.meta.breadcrumb);

    return {
      breadcrumb,
    };
  },
});
</script>
