
import { defineComponent, inject } from "vue";

export default defineComponent({
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {},
  setup() {
    const activeTab = inject("activeTab");

    return {
      activeTab,
    };
  },
});
