
import { defineComponent } from "vue";
import { ChevronDownIcon } from "@zhuowenli/vue-feather-icons";
import { linkTo } from "./index";

export default defineComponent({
  components: {
    ChevronDownIcon,
  },
  props: ["menu", "allowOpen"],
  setup(props) {
    return {
      props,
      linkTo,
    };
  },
});
