<template>
  <div v-html="content"></div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import MarkdownIt from "markdown-it";

export default defineComponent({
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  components: {},
  setup(props) {
    const md = new MarkdownIt();
    const content = computed(() => md.render(props.source));
    return {
      content,
    };
  },
});
</script>
