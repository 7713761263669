<template>
  <router-link
    :to="{ name: menu.pageName, params: menu.params || {} }"
    tag="a"
    class="top-menu"
    :class="{
      [menu.activeClass]: menu.active && menu.activeClass && allowActive,
      'top-menu--active': menu.active && allowActive,
    }"
  >
    <div class="top-menu__icon">
      <component v-if="menu.icon" :is="menu.icon" />
    </div>
    <div class="top-menu__title">
      {{ menu.title }}
      <ChevronDownIcon v-if="menu.subMenu" class="top-menu__sub-icon" />
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChevronDownIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    ChevronDownIcon,
  },
  props: ["menu", "allowActive"],
  setup() {
    return {};
  },
});
</script>
