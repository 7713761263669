
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { ChevronRightIcon } from "@zhuowenli/vue-feather-icons";

export default defineComponent({
  components: {
    ChevronRightIcon,
  },
  setup() {
    const route = useRoute();
    const breadcrumb = computed(() => route.meta.breadcrumb);

    return {
      breadcrumb,
    };
  },
});
