<template>
  <div
    class="relative -mt-6 rounded-t-2xl p-6 pb-12 md:p-12 pt-9 mb-0"
    :class="{
      'bg-gradient-to-b from-cebai-primary-gradient-start to-cebai-primary text-white':
        variant === 'primary',
      'bg-cebai-primary-tint text-white': variant === 'primary-tint',
      'bg-cebai-tertiary text-white': variant === 'tertiary',
      'bg-white text-cebai-primary ': variant === 'white',
      'bg-cebai-tint text-primary border-t border-gray-400': variant === 'tint',
    }"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  components: {},
  setup() {
    return {};
  },
});
</script>
