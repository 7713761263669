
import { defineComponent, computed, ref, watch, onMounted, provide } from "vue";
import { TinySliderInstance } from "tiny-slider";
import { useStore } from "@/store";
import TippyContent from "@/components/rubick/tippy-content/Main.vue";
import TinySlider from "@/components/rubick/tiny-slider/Main.vue";
import PageTitle from "@/components/ui/page-title/PageTitle.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import LoadingIcon from "@/components/rubick/loading-icon/Main.vue";

export default defineComponent({
  components: {
    PageTitle,
    Editorial,
    Button,
    Svg,
    Markdown,
    PageContent,
    PageContentBlock,
    Tabs,
    Tab,
    TabContent,
    LoadingIcon,
    TippyContent,
    TinySlider,
  },
  setup() {
    const store = useStore();

    // alerts
    const addInfo = () =>
      store.dispatch("messages/ADD_INFO_MESSAGE", "Info message", {
        root: true,
      });
    const addSuccess = () =>
      store.dispatch("messages/ADD_SUCCESS_MESSAGE", "Success message", {
        root: true,
      });
    const addError = () =>
      store.dispatch("messages/ADD_ERROR_MESSAGE", "Error message", {
        root: true,
      });

    // markdown
    const markdownContent = `
## h2 title

- unordered
- list

a paragraph of text

### h3 title

some text with __bold__ and *italic*`;

    // svgs
    const svgPath = "_placeholder.svg";

    // tabs
    const tabs = {
      "tab-one": "Tab one content",
      "tab-two": "Tab two content",
      "tab-three": "Tab three content",
      "tab-four": "Tab four content",
    };
    const activeTab = computed(() => Object.keys(tabs)[0]);

    // loading
    const isLoading = ref(true);
    const toggleLoadingState = () => (isLoading.value = !isLoading.value);

    // tooltips
    const tippyOptions = {
      interactive: true,
      hideOnClick: true,
      appendTo: document.body,
      maxWidth: 380,
      onShow() {
        console.log("tooltip shown");
      },
      onHide() {
        console.log("tooltip hidden");
      },
    };

    // slider
    const tinySliderOptions = {
      autoHeight: true,
      mouseDrag: false,
      touch: false,
      autoplay: false,
      loop: false,
    };

    // store a reference to the slider HTML element
    const exampleSlider = ref<HTMLElement>();
    provide("bind[exampleSlider]", (el: HTMLElement) => {
      exampleSlider.value = el;
    });

    // use slider HTML element reference
    let slider: TinySliderInstance;

    // when the active slide changes, update the slider
    const slide = ref(0);
    watch(slide, () => {
      slider.goTo(slide.value);
    });

    onMounted(() => {
      // on initial load, update the slider
      const sliderEl: any = exampleSlider.value;
      slider = sliderEl.tns;
      slider.goTo(slide.value);
    });

    // provide method to allow individual slides to tell the slider to update
    const updateSliderDimensions = () => {
      slider.refresh();
    };

    const goToSlide = (num: number) => (slide.value = num);

    return {
      addInfo,
      addSuccess,
      addError,
      markdownContent,
      svgPath,
      tabs,
      activeTab,
      isLoading,
      toggleLoadingState,
      tippyOptions,
      tinySliderOptions,
      updateSliderDimensions,
      goToSlide,
      slide,
    };
  },
});
