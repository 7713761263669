
import { defineComponent } from "vue";
import HomeTitle from "@/components/content/home-title/HomeTitle.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import ButtonRouterLink from "@/components/ui/button/ButtonRouterLink.vue";

export default defineComponent({
  components: {
    HomeTitle,
    PageContent,
    PageContentBlock,
    ButtonRouterLink,
  },
  setup() {
    console.log("Home view!");
    return {};
  },
});
