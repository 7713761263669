<template>
  <button
    class="md:mx-px focus:outline-none transition"
    :class="{
      'bg-cebai-primary': activeTab === slug,
      'bg-cebai-tertiary hover:bg-cebai-primary': activeTab !== slug,
      'md:rounded-l-full': first,
      'md:rounded-r-full': last,
    }"
    @click="() => updateActiveTab(slug)"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

export default defineComponent({
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
    first: {
      type: Boolean,
      required: false,
      default: false,
    },
    last: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  setup() {
    const activeTab = inject("activeTab");
    const updateActiveTab = inject("updateActiveTab");

    return {
      activeTab,
      updateActiveTab,
    };
  },
});
</script>
