// Create initial state
export const state = {
  menu: [
    {
      icon: "",
      pageName: "home",
      title: "Home",
      active: true,
      activeClass: "bg-cebai-primary",
    },
  ],
};

// Declare state
export type State = typeof state;
