<template>
  <div
    class="relative z-10 border-b border-cebai-secondary -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10"
  >
    <div class="top-bar-boxed flex items-center">
      <HeaderLogo />

      <HeaderBreadcrumb />

      <!-- Spacer -->
      <div class="intro-x relative mr-3 sm:mr-6"></div>

      <!-- <HeaderNotifications /> -->
      <!-- <HeaderAccount /> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderLogo from "./HeaderLogo.vue";
import HeaderBreadcrumb from "./HeaderBreadcrumb.vue";
// import HeaderNotifications from "./HeaderNotifications.vue";
// import HeaderAccount from "./HeaderAccount.vue";

export default defineComponent({
  components: {
    HeaderLogo,
    HeaderBreadcrumb,
    // HeaderNotifications,
    // HeaderAccount,
  },
  setup() {
    return {};
  },
});
</script>
