<template>
  <div class="mobile-menu md:hidden relative z-10">
    <div class="mobile-menu-bar">
      <a href class="flex mr-auto text-white"> BizBrain </a>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="border-t border-cebai-secondary py-5">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else :key="menu + menuKey">
            <MobileMenuLink :menu="menu" :allow-open="true" />

            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <MobileMenuLink :menu="subMenu" />

                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <MobileMenuLink :menu="lastSubMenu" />
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { BarChart2Icon } from "@zhuowenli/vue-feather-icons";
import { useStore } from "@/store";
import { nestedMenu } from "@/components/composables/menu";
import MobileMenuLink from "./MobileMenuLink.vue";
import { activeMobileMenu, toggleMobileMenu, enter, leave } from "./index";

export default defineComponent({
  components: {
    BarChart2Icon,
    MobileMenuLink,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const path = computed(() => route.path);
    const mobileMenu = computed(() =>
      nestedMenu(store.state.mainMenu.menu, route)
    );

    watch(path, () => {
      formattedMenu.value = JSON.parse(JSON.stringify(mobileMenu.value));
    });

    onMounted(() => {
      formattedMenu.value = JSON.parse(JSON.stringify(mobileMenu.value));
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      enter,
      leave,
    };
  },
});
</script>
