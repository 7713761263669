<template>
  <router-link
    :to="{ name: menu.pageName, params: menu.params || {} }"
    tag="a"
    class="menu"
    :class="{
      'menu--active': menu.active,
      'menu--open': menu.activeDropdown && allowOpen,
    }"
    @click="linkTo(menu, router)"
  >
    <div class="menu__title">
      {{ menu.title }}
      <div
        v-if="menu.subMenu"
        class="menu__sub-icon"
        :class="{ 'transform rotate-180': menu.activeDropdown }"
      >
        <ChevronDownIcon />
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChevronDownIcon } from "@zhuowenli/vue-feather-icons";
import { linkTo } from "./index";

export default defineComponent({
  components: {
    ChevronDownIcon,
  },
  props: ["menu", "allowOpen"],
  setup(props) {
    return {
      props,
      linkTo,
    };
  },
});
</script>
