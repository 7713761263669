
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const primaryLinks = [
      {
        title: "Footer - primary link",
        slug: "home",
      },
    ];

    const secondaryLinks = [
      {
        title: "Footer - secondary link",
        slug: "home",
      },
    ];

    const year = new Date().getFullYear();

    return {
      primaryLinks,
      secondaryLinks,
      year,
    };
  },
});
