<template>
  <PageTitle>
    <Editorial align="centre">
      <h1>Component Library</h1>
      <p class="text-center">Available UI components</p>
    </Editorial>
  </PageTitle>
  <PageContent>
    <!-- Alerts -->

    <PageContentBlock variant="tint">
      <p class="mb-6">Alerts</p>
      <button class="button mr-1 bg-theme-1 text-white" @click="addInfo">
        Info
      </button>
      <button class="button mr-1 bg-theme-9 text-white" @click="addSuccess">
        Success
      </button>
      <button class="button mr-1 bg-theme-6 text-white" @click="addError">
        Error
      </button>
    </PageContentBlock>

    <!-- Buttons -->

    <PageContentBlock variant="white">
      <p class="mb-6">Buttons</p>
      <p class="my-2">Types</p>
      <div class="flex mb-6">
        <Button>Default</Button>
        <Button type="primary">Primary</Button>
        <Button type="secondary">Secondary</Button>
      </div>
      <div class="flex mb-6">
        <Button type="light">Light</Button>
        <Button type="outline-dark">Outline-dark</Button>
        <Button type="outline-gray">Outline-gray</Button>
        <Button type="outline-light">Outline-light</Button>
        <Button type="danger">Danger</Button>
        <Button type="text">Text</Button>
      </div>
      <hr />
      <p class="my-6">Sizes</p>
      <Button class="mb-2">Default</Button>
      <Button class="mb-2" size="small">Small</Button>
      <Button class="mb-2" size="xs">xs</Button>
    </PageContentBlock>

    <!-- Editorial -->

    <PageContentBlock variant="tertiary">
      <p class="mb-6">Editorial</p>
      <Editorial>
        <h1>h1</h1>
        <h2>h2</h2>
        <h3>h3</h3>
        <h4>h4</h4>
        <p>p</p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias ipsum
          tenetur assumenda! Quaerat quia a rerum tenetur repellendus assumenda
          vero, fugiat vel similique hic nulla explicabo repudiandae. Hic, amet
          earum.
        </p>
      </Editorial>
      <hr />
      <p class="my-6">Editorial - centred</p>
      <Editorial align="centre">
        <h1>h1</h1>
        <h2>h2</h2>
        <h3>h3</h3>
        <h4>h4</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias ipsum
          tenetur assumenda! Quaerat quia a rerum tenetur repellendus assumenda
          vero, fugiat vel similique hic nulla explicabo repudiandae. Hic, amet
          earum.
        </p>
      </Editorial>
    </PageContentBlock>

    <!-- SVGs -->

    <PageContentBlock variant="tint">
      <p class="my-6">SVGs</p>
      <div class="flex items-center justify-center">
        <div class="w-24 h-24">
          <Svg :img="svgPath" />
        </div>
        <div class="w-24 h-24">
          <Svg img="_placeholder.svg" class="svg-paths-black" />
        </div>
        <div class="w-24 h-24">
          <Svg img="_placeholder.svg" class="svg-paths-white" />
        </div>
        <div class="w-24 h-24">
          <Svg
            img="_placeholder.svg"
            class="text-cebai-secondary fill-current"
          />
        </div>
        <div class="w-24 h-24">
          <Svg
            img="_placeholder.svg"
            class="text-cebai-secondary stroke-current"
          />
        </div>
        <div class="w-12 h-12">
          <Svg img="_placeholder.svg" />
        </div>
        <div class="w-36 h-36">
          <Svg img="_placeholder.svg" />
        </div>
      </div>
    </PageContentBlock>

    <!-- Tabs -->

    <PageContentBlock variant="primary-tint">
      <p class="my-6">Tabs</p>
      <Tabs :activeTab="activeTab">
        <template #tabs>
          <Tab
            v-for="(tab, slug, index) in tabs"
            :key="slug"
            :slug="slug"
            :first="index === 0"
            :last="index === Object.keys(tabs).length - 1"
          >
            <p class="p-5">Tab {{ index + 1 }}</p>
          </Tab>
        </template>
        <template #tab-content>
          <TabContent v-for="(tab, slug) in tabs" :key="slug" :slug="slug">
            {{ tab }}
          </TabContent>
        </template>
      </Tabs>
    </PageContentBlock>

    <!-- Markdown -->

    <PageContentBlock variant="tertiary">
      <p class="my-6">Markdown</p>
      <Editorial>
        <Markdown :source="markdownContent" />
      </Editorial>
    </PageContentBlock>

    <!-- Loading -->

    <PageContentBlock variant="tint">
      <p class="mb-6">Loading icon</p>
      <div class="mb-6">
        <div v-if="isLoading" class="w-6">
          <LoadingIcon color="black" icon="oval" />
        </div>
        <div v-else>
          <p>Content is loaded!</p>
        </div>
      </div>
      <Button type="primary" size="xs" @click="toggleLoadingState">
        Toggle loading state
      </Button>
    </PageContentBlock>

    <!-- Tooltips -->

    <PageContentBlock variant="white">
      <p class="mb-6">Tippy (custom tooltips)</p>
      <div class="flex">
        <Button
          :name="`custom-tooltip-content-unique-id`"
          type="primary"
          size="xs"
        >
          Click here to open custom tooltip
        </Button>
        <Button
          :name="`custom-tooltip-content-another-unique-id`"
          type="secondary"
          size="xs"
        >
          Another custom tooltip
        </Button>
      </div>
      <div class="tooltip-content">
        <TippyContent
          :to="`custom-tooltip-content-unique-id`"
          :options="tippyOptions"
        >
          <div class="px-5 py-2 flex items-center justify-center">
            <div class="w-12 h-12 mr-2">
              <Svg img="_placeholder.svg" />
            </div>
            <p>This is the custom tooltip content</p>
          </div>
        </TippyContent>
        <TippyContent
          :to="`custom-tooltip-content-another-unique-id`"
          :options="tippyOptions"
        >
          <div class="bg-cebai-tint p-20">
            <p class="text-xl text-cebai-primary-tint">
              Another custom tooltip
            </p>
          </div>
        </TippyContent>
      </div>
    </PageContentBlock>

    <!-- Sliders -->

    <PageContentBlock variant="primary-tint">
      <p class="mb-6">Slider (carousel)</p>
      <p class="mb-6">
        <em>Current slide: {{ slide }}</em>
      </p>
      <div class="flex mb-6">
        <Button
          :type="slide === 0 ? 'outline-light' : 'secondary'"
          size="xs"
          @click="() => goToSlide(0)"
        >
          Go to slide 1
        </Button>
        <Button
          :type="slide === 1 ? 'outline-light' : 'secondary'"
          size="xs"
          @click="() => goToSlide(1)"
        >
          Go to slide 2
        </Button>
        <Button
          :type="slide === 2 ? 'outline-light' : 'secondary'"
          size="xs"
          @click="() => goToSlide(2)"
        >
          Go to slide 3
        </Button>
      </div>

      <div class="overflow-hidden">
        <TinySlider refKey="exampleSlider" :options="tinySliderOptions">
          <div class="slide-1">
            <p class="mb-6">Slide 1 content</p>
          </div>
          <div class="slide-2 p-5 border-white border-2 rounded-sm">
            <p class="mb-6">Slide 2 content</p>
            <div class="w-12 h-12 mr-2">
              <Svg img="_placeholder.svg" class="svg-paths-white" />
            </div>
          </div>
          <div class="slide-3 bg-white text-cebai-primary p-5">
            <p class="mb-6">Slide 3 content</p>
            <Editorial>
              <Markdown :source="markdownContent" />
            </Editorial>
          </div>
        </TinySlider>
      </div>
    </PageContentBlock>
    <PageContentBlock variant="primary">
      <p>
        That's it, for now
        <span class="typing-dots">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </p>
    </PageContentBlock>
  </PageContent>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted, provide } from "vue";
import { TinySliderInstance } from "tiny-slider";
import { useStore } from "@/store";
import TippyContent from "@/components/rubick/tippy-content/Main.vue";
import TinySlider from "@/components/rubick/tiny-slider/Main.vue";
import PageTitle from "@/components/ui/page-title/PageTitle.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Markdown from "@/components/ui/markdown/Markdown.vue";
import PageContent from "@/components/layout/page-content/PageContent.vue";
import PageContentBlock from "@/components/layout/page-content/PageContentBlock.vue";
import Svg from "@/components/ui/svg/Svg.vue";
import Button from "@/components/ui/button/Button.vue";
import Tabs from "@/components/ui/tabs/Tabs.vue";
import Tab from "@/components/ui/tabs/Tab.vue";
import TabContent from "@/components/ui/tabs/TabContent.vue";
import LoadingIcon from "@/components/rubick/loading-icon/Main.vue";

export default defineComponent({
  components: {
    PageTitle,
    Editorial,
    Button,
    Svg,
    Markdown,
    PageContent,
    PageContentBlock,
    Tabs,
    Tab,
    TabContent,
    LoadingIcon,
    TippyContent,
    TinySlider,
  },
  setup() {
    const store = useStore();

    // alerts
    const addInfo = () =>
      store.dispatch("messages/ADD_INFO_MESSAGE", "Info message", {
        root: true,
      });
    const addSuccess = () =>
      store.dispatch("messages/ADD_SUCCESS_MESSAGE", "Success message", {
        root: true,
      });
    const addError = () =>
      store.dispatch("messages/ADD_ERROR_MESSAGE", "Error message", {
        root: true,
      });

    // markdown
    const markdownContent = `
## h2 title

- unordered
- list

a paragraph of text

### h3 title

some text with __bold__ and *italic*`;

    // svgs
    const svgPath = "_placeholder.svg";

    // tabs
    const tabs = {
      "tab-one": "Tab one content",
      "tab-two": "Tab two content",
      "tab-three": "Tab three content",
      "tab-four": "Tab four content",
    };
    const activeTab = computed(() => Object.keys(tabs)[0]);

    // loading
    const isLoading = ref(true);
    const toggleLoadingState = () => (isLoading.value = !isLoading.value);

    // tooltips
    const tippyOptions = {
      interactive: true,
      hideOnClick: true,
      appendTo: document.body,
      maxWidth: 380,
      onShow() {
        console.log("tooltip shown");
      },
      onHide() {
        console.log("tooltip hidden");
      },
    };

    // slider
    const tinySliderOptions = {
      autoHeight: true,
      mouseDrag: false,
      touch: false,
      autoplay: false,
      loop: false,
    };

    // store a reference to the slider HTML element
    const exampleSlider = ref<HTMLElement>();
    provide("bind[exampleSlider]", (el: HTMLElement) => {
      exampleSlider.value = el;
    });

    // use slider HTML element reference
    let slider: TinySliderInstance;

    // when the active slide changes, update the slider
    const slide = ref(0);
    watch(slide, () => {
      slider.goTo(slide.value);
    });

    onMounted(() => {
      // on initial load, update the slider
      const sliderEl: any = exampleSlider.value;
      slider = sliderEl.tns;
      slider.goTo(slide.value);
    });

    // provide method to allow individual slides to tell the slider to update
    const updateSliderDimensions = () => {
      slider.refresh();
    };

    const goToSlide = (num: number) => (slide.value = num);

    return {
      addInfo,
      addSuccess,
      addError,
      markdownContent,
      svgPath,
      tabs,
      activeTab,
      isLoading,
      toggleLoadingState,
      tippyOptions,
      tinySliderOptions,
      updateSliderDimensions,
      goToSlide,
      slide,
    };
  },
});
</script>
